<template>
  <v-container>
    <v-card class="pa-4">
      <h2 class="mt-5">FINANCE REPORT TABLE</h2>
      <v-simple-table class="mt-4">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">PERFORMANCE INDICATORS</th>
              <th class="text-left">Unit of Measure</th>
              <th class="text-left">Nov</th>
              <th class="text-left">Dec</th>
              <th class="text-left">Jan</th>
              <th class="text-left">Ist Quater</th>
              <th class="text-left">Feb</th>
              <th class="text-left">Mar</th>
              <th class="text-left">April</th>
              <th class="text-left">2nd Quater</th>
              <th class="text-left">May</th>
              <th class="text-left">Jun</th>
              <th class="text-left">July</th>
              <th class="text-left">3rd Quater</th>
              <th class="text-left">Aug</th>
              <th class="text-left">Sept</th>
              <th class="text-left">Oct</th>
              <th class="text-left">4th Quater</th>
              <th class="text-left">Annual</th>
              <th class="text-left">Project to date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Withdrawal application submitted</td>
              <td>No</td>
              <td v-for="(td, index) in tds" :key="index"></td>
            </tr>
            <tr>
              <td>Replenishment Application submitted</td>
              <td>No</td>
              <td v-for="(td, index) in tds" :key="index"></td>
            </tr>
            <tr>
              <td>Replenishment received</td>
              <td>No</td>
              <td v-for="(td, index) in tds" :key="index"></td>
            </tr>
            <tr>
              <td>Amount replenished</td>
              <td>NGN</td>
              <td v-for="(td, index) in tds" :key="index"></td>
            </tr>
            <tr>
              <td>Government Contribution</td>
              <td>NGN</td>
              <td v-for="(td, index) in tds" :key="index"></td>
            </tr>
            <tr>
              <td>Interest</td>
              <td>NGN</td>
              <td v-for="(td, index) in tds" :key="index"></td>
            </tr>
            <tr>
              <td>Income Total</td>
              <td>NGN</td>
              <td v-for="(td, index) in tds" :key="index"></td>
            </tr>
            <tr>
              <td>Goods</td>
              <td>NGN</td>
              <td v-for="(td, index) in tds" :key="index"></td>
            </tr>
            <tr>
              <td>Non-Consulting</td>
              <td>NGN</td>
              <td v-for="(td, index) in tds" :key="index"></td>
            </tr>
            <tr>
              <td>Consultancy</td>
              <td>NGN</td>
              <td v-for="(td, index) in tds" :key="index"></td>
            </tr>
            <tr>
              <td>Operating Cost</td>
              <td>NGN</td>
              <td v-for="(td, index) in tds" :key="index"></td>
            </tr>
            <tr>
              <td>Training</td>
              <td>NGN</td>
              <td v-for="(td, index) in tds" :key="index"></td>
            </tr>
            <tr>
              <td>Grants - CDP</td>
              <td>NGN</td>
              <td v-for="(td, index) in tds" :key="index"></td>
            </tr>
            <tr>
              <td>Grants - GDP</td>
              <td>NGN</td>
              <td v-for="(td, index) in tds" :key="index"></td>
            </tr>
            <tr>
              <td>Expenditure Total</td>
              <td>NGN}</td>
              <td v-for="(td, index) in tds" :key="index"></td>
            </tr>
            <tr>
              <td>Balance in IDA Replenished A/C</td>
              <td>NGN</td>
              <td v-for="(td, index) in tds" :key="index"></td>
            </tr>
            <tr>
              <td>Balance in Govt. Contribution A/C</td>
              <td>NGN</td>
              <td v-for="(td, index) in tds" :key="index"></td>
            </tr>
            <tr>
              <td>Balance in Interest Account:</td>
              <td>NGN</td>
              <td v-for="(td, index) in tds" :key="index"></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <!-- <vue-excel-xlsx
      class="mt-2 text-center"
      :data="[finances]"
      :columns="financeHeaders"
      :filename="'Finance Records'"
      :sheetname="'Finance Records Sheet'"
    >
      <v-btn small rounded class="white--text" color="primary">
        Export to Excel</v-btn
      >
    </vue-excel-xlsx> -->
  </v-container>
</template>

<script>
import { apiClient } from "@/services";
export default {
  data() {
    return {
      cdps: [],
      tds: [
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
      ivaHeaders: [
        { label: "Total No of CDPs", field: "totalCDPS" },
        { label: "No of CDP Approved", field: "totalCDP" },
        { label: "No of GrDP Approved", field: "totalGrDP" },
        { label: "No of Profile of Beneficiaries of CDPs", field: "envScreen" },
        {
          label: "No of Profile of Beneficiaries of GrDPs",
          field: "socialScreen",
        },
        {
          label: "No of Environmental Screening Conducted",
          field: "cdpProfile",
        },
        { label: "No of Social Screening Conducted", field: "grdpProfile" },
        { label: "No of VLD Protocol Developed", field: "vld" },
        { label: "No of Environmental Audit Conducted", field: "envAudit" },
        { label: "No of Social Audit Conducted", field: "socialAudit" },
      ],
    };
  },

  created() {
    apiClient
      .get("/finance?u=2")
      .then((res) => {
        // console.log(res.data)
        if (Array.isArray(res.data)) {
          this.cdps = res.data;
        } else {
          this.displayMsg(res.data, "error");
        }
      })
      .catch((err) => this.displayMsg(err.message, "error"));
  },
};
</script>
